.l-hero {
  background:#A9A3D7;
  .slick-list {

  }
  &__slide {
    position:relative;
  }
  &__link {
    position:Absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
  }
  .b-button {
    margin-top:40px;
  }

  &__text {
    h1 {
      @media (max-width:767px) {
        padding-left:0;
        padding-right:0;
        font-size:45px;
      }
    }
  }
}

.home .l-hero {
  //height:423px;
}

.slides > li {
  height: 100%;

}

.narrow-height .l-hero {
  max-height:535px;
}


.page-id-23540 .l-hero__slide {
  background-position: 50% center;
}

.pagebuilder .l-hero {
  @media (min-width:768px) {
   // margin-top:110px;
  }
  @media (max-width:767px) {
  //  margin-top:139px;
  }
}

// custom overrides

@media (min-width: 1280px) {
  .page-id-14 .l-hero, .page-id-21365 .l-hero {
    height:80vh;
    max-height: 620px;
  }
  .page-id-14 .l-hero__text {
     width: auto;
     position: static;
     left: auto;
     margin-left: auto;
     margin-top: auto;
  }
  .page-id-14 .l-hero__slide > .ab-center {
    top:50%;
  }
  .page-id-14 .ab-center {
    text-align:center!important;
  }
  .page-id-14 .l-hero .h-100,
  .page-id-21365 .l-hero .h-100
  {
     display: block;
    min-height:500px;
  }

}
