.promo-circles {
  &-wrap {
    display:flex;
    flex-wrap:wrap;
    justify-content: center;
  }
  &__item {
    text-align:center;
    @media(max-width:767px) {
      margin-bottom:30px;
    }
  }
  &__image {
    @include aspect-ratio(3,3);
    border-radius:100%;
    margin:20px auto 15px auto;
    width:85%;

  }
}