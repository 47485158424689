.r-tabs .r-tabs-nav {
  margin: 0;
  padding: 0; }

.r-tabs .r-tabs-tab {
  display: inline-block;
  margin: 0;
  list-style: none; }

.r-tabs .r-tabs-panel {
  padding: 15px;
  display: none; }

.r-tabs .r-tabs-accordion-title {
  display: none; }

.r-tabs .r-tabs-panel.r-tabs-state-active {
  display: block; }

/* Accordion responsive breakpoint */
@media only screen and (max-width: 768px) {
  .r-tabs .r-tabs-nav {
    display: none; }
  .r-tabs .r-tabs-accordion-title {
    display: block; } }

/* ==========================================================================
   4. Mixins
   ========================================================================== */
/**************************************************************
-> Usage: @include aspect-ratio(16, 9);
**************************************************************/
/**************************************************************
-> Usage: @include fluid-type(650px, 1600px, 16px, 32px);
**************************************************************/
/**************************************************************
-> Usage: width:get-vw(100px);
**************************************************************/
/* ==========================================================================
   6. Custom Styles
   ========================================================================== */
@media (min-width: 992px) {
  .l-masthead .header .menu .subnav-container ul.subnav .secondary-subnav ul {
    width: 55%; } }

.cen {
  text-align: center; }

.centre-all {
  text-align: center; }

ul li {
  margin-left: 25px; }
  ul li:before {
    left: -25px; }

.ratio-image {
  overflow: hidden;
  display: block;
  margin: 0;
  position: relative; }
  .ratio-image::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 50%; }
  .ratio-image > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .ratio-image img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 100%; }
    .ratio-image img.height-100 {
      height: 100%;
      width: auto;
      max-width: 1000%; }

.single-course > .row,
.single-course > .col-md-12 {
  max-width: 1146px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 15px;
  float: none; }

.page-template-template-pagebuilder .no-content {
  display: none; }

.pagebuilder section {
  margin: 40px 0;
  clear: both; }

.pagebuilder .no-top {
  margin-top: 0 !important; }

.pagebuilder .no-bottom {
  margin-bottom: 0 !important; }

.pagebuilder .no-padding {
  margin: 0 !important; }

#pagebuilder-top + section {
  margin-top: 0; }

.pagebuilder .contact-module .spacer {
  padding: 20px; }

.pagebuilder section.buttons .container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }

.pagebuilder section.buttons .b-button {
  margin: 10px; }

@media (max-width: 450px) {
  .pagebuilder .b-button {
    width: auto; } }

@media (min-width: 769px) {
  .pagebuilder .b-button--secondary {
    padding: 15px 30px;
    font-size: 24px; } }

.spacer-line .container {
  padding-top: 40px;
  padding-bottom: 40px;
  clear: both; }
  .spacer-line .container hr {
    border: none;
    border-top: 1px solid #111; }

.row-of-images-wrap {
  display: flex;
  justify-content: center; }
  .row-of-images-wrap .ratio-image {
    margin: 0 5px 10px 5px;
    width: 30%;
    position: relative; }
    .row-of-images-wrap .ratio-image::before {
      display: block;
      content: "";
      width: 100%;
      padding-top: 100%; }
    .row-of-images-wrap .ratio-image > .content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }

.choose-form .gform_wrapper {
  display: block !important; }

.consultation-form .gform_title {
  margin-bottom: 5px; }

.consultation-form label {
  margin-bottom: 5px; }

.gallery-wrap {
  display: flex; }
  @media (max-width: 540px) {
    .gallery-wrap {
      flex-wrap: wrap; } }

.gallery__image {
  width: 20%;
  position: relative;
  margin: 0 5px 10px 5px; }
  .gallery__image::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 100%; }
  .gallery__image > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  @media (max-width: 540px) {
    .gallery__image {
      width: 46%;
      margin: 0 2% 10px 2%; } }

.block-contact-module {
  clear: both; }
  .block-contact-module .b-contact-module__apply {
    display: block; }

.news {
  clear: both; }
  @media (max-width: 767px) {
    .news .col-sm-3 {
      margin-bottom: 30px !important; } }

body.logged-in .l-masthead {
  top: 29px; }

.mobile-nav .mobile-menu li a {
  height: auto; }

@media (max-width: 991px) {
  .page-id-17195 {
    padding-top: 93px; } }

@media (max-width: 767px) {
  .page-id-17195 {
    padding-top: 125px; } }

@media (min-width: 992px) {
  .news .news-container .alm-listing li {
    width: 33.33%; } }

.alm-listing .alm-reveal {
  display: flex;
  flex-wrap: wrap; }

@media only screen and (min-width: 992px) {
  .l-masthead .header .menu .subnav-container {
    left: -50%; } }

.l-masthead li:before {
  display: none; }

.search-bar label {
  opacity: 0;
  height: 0;
  width: 0;
  overflow: hidden; }

.mobile-menu li:before {
  display: none; }

.b-social-mobile a {
  font-size: 0;
  color: transparent; }

.b-social-mobile span {
  font-size: 20px;
  color: #fff; }

.course-details {
  clear: both; }

#coursetabs {
  border-top: 10px solid #F1C400;
  border-bottom: 10px solid #F1C400;
  background: #F8F8F8; }
  #coursetabs .row {
    margin: 0; }

.course-details__title, .r-tabs-anchor {
  clear: both;
  border-color: #e3e3e3;
  background-color: #ebebeb;
  color: #666;
  display: block !important;
  list-style-type: none;
  text-transform: uppercase;
  text-decoration: none; }
  .course-details__title:before, .r-tabs-anchor:before {
    display: none; }
  .course-details__title a, .r-tabs-anchor a {
    text-decoration: none;
    color: #666; }
  .course-details__title h3, .r-tabs-anchor h3 {
    font-size: 18px;
    font-family: "Nunito", sans-serif;
    color: #666; }
  .course-details__title i, .r-tabs-anchor i {
    width: 30px;
    display: inline-block; }
  .course-details__title.r-tabs-state-active, .r-tabs-anchor.r-tabs-state-active {
    background: #F8F8F8; }

.r-tabs-anchor {
  padding: 14px 20px; }

.course-details__content {
  padding: 14px 20px; }
  .course-details__content p {
    font-size: 18px; }

.course-details-timetable .course-details-timetable-wrap {
  min-width: 700px;
  overflow-x: scroll; }

.course-details-timetable__item {
  padding-left: 8px;
  padding-right: 8px;
  width: 14%;
  font-size: 14px;
  border-top: 1px solid grey;
  float: left; }
  .course-details-timetable__item:first-of-type {
    border-left: 1px solid grey; }
  .course-details-timetable__item:last-of-type {
    border-right: 1px solid grey; }
  .course-details-timetable__item p {
    font-size: 14px;
    line-height: 1.2em; }

.course-details-timetable__title {
  background: white;
  width: Auto;
  margin: 0 -9px 0 -8px;
  padding: 8px;
  color: black; }
  .course-details-timetable__title p {
    font-weight: bold; }

.course-details-timetable__item:last-of-type .course-details-timetable__title {
  margin-right: -8px; }

.course-details-timetable__activity {
  padding: 8px 0;
  height: 7em;
  position: relative; }
  .course-details-timetable__activity:After {
    content: '';
    border-bottom: 1px solid grey;
    display: block;
    position: Absolute;
    bottom: 0;
    left: -8px;
    right: -8px; }

.course-details-timetable .course-details__item__morning:before {
  content: '';
  border-bottom: 1px solid grey;
  display: block;
  position: Absolute;
  top: 0;
  left: -8px;
  right: -8px; }

.course-details-facilities h3 {
  font-size: 23px; }

.course-details-gallery li {
  list-style-type: none;
  padding: 0;
  width: 19%;
  margin: 0 1px 1px 0; }
  .course-details-gallery li a {
    width: 100%; }
  .course-details-gallery li img {
    width: 100%; }
  .course-details-gallery li:before {
    display: none; }

.l-hero {
  background: #A9A3D7; }
  .l-hero__slide {
    position: relative; }
  .l-hero__link {
    position: Absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0; }
  .l-hero .b-button {
    margin-top: 40px; }
  @media (max-width: 767px) {
    .l-hero__text h1 {
      padding-left: 0;
      padding-right: 0;
      font-size: 45px; } }

.slides > li {
  height: 100%; }

.narrow-height .l-hero {
  max-height: 535px; }

.page-id-23540 .l-hero__slide {
  background-position: 50% center; }

@media (min-width: 1280px) {
  .page-id-14 .l-hero, .page-id-21365 .l-hero {
    height: 80vh;
    max-height: 620px; }
  .page-id-14 .l-hero__text {
    width: auto;
    position: static;
    left: auto;
    margin-left: auto;
    margin-top: auto; }
  .page-id-14 .l-hero__slide > .ab-center {
    top: 50%; }
  .page-id-14 .ab-center {
    text-align: center !important; }
  .page-id-14 .l-hero .h-100,
  .page-id-21365 .l-hero .h-100 {
    display: block;
    min-height: 500px; } }

.price-section {
  clear: both;
  text-align: center; }
  .price-section .row {
    justify-content: center;
    display: flex; }
    .price-section .row:before, .price-section .row:after {
      content: " ";
      display: table; }
    .price-section .row:after {
      clear: both; }
  .price-section__item {
    text-align: center; }
  .price-section__image {
    position: relative; }
    .price-section__image::before {
      display: block;
      content: "";
      width: 100%;
      padding-top: 116.95906%; }
    .price-section__image > .content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
  .price-section__title {
    text-align: center;
    font-family: "bebas-neue", serif;
    text-align: center;
    color: #7f56c5;
    font-size: 30px; }
  .price-section__bottom {
    margin-top: 40px; }
    .price-section__bottom:before, .price-section__bottom:after {
      content: " ";
      display: table; }
    .price-section__bottom:after {
      clear: both; }
  .price-section__message p, .price-section__message a {
    font-size: 24px;
    font-weight: bold; }

.promo-boxes {
  clear: both;
  text-align: center; }
  .promo-boxes .row {
    margin: 0; }
    .promo-boxes .row:before, .promo-boxes .row:after {
      content: " ";
      display: table; }
    .promo-boxes .row:after {
      clear: both; }
  .promo-boxes__item {
    text-align: center; }
    .promo-boxes__item .b-button {
      margin-top: 20px; }
  .promo-boxes__image {
    position: relative; }
    .promo-boxes__image::before {
      display: block;
      content: "";
      width: 100%;
      padding-top: 116.95906%; }
    .promo-boxes__image > .content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
  .promo-boxes__title {
    text-align: center;
    font-family: "bebas-neue", serif;
    text-align: center;
    color: #7f56c5;
    font-size: 30px;
    margin-bottom: 17px; }
  .promo-boxes__bottom {
    margin-top: 40px; }
    .promo-boxes__bottom:before, .promo-boxes__bottom:after {
      content: " ";
      display: table; }
    .promo-boxes__bottom:after {
      clear: both; }
  .promo-boxes__message p, .promo-boxes__message a {
    font-size: 24px;
    font-weight: bold; }

.promo-circles-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }

.promo-circles__item {
  text-align: center; }
  @media (max-width: 767px) {
    .promo-circles__item {
      margin-bottom: 30px; } }

.promo-circles__image {
  position: relative;
  border-radius: 100%;
  margin: 20px auto 15px auto;
  width: 85%; }
  .promo-circles__image::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 100%; }
  .promo-circles__image > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }

.summer-school-intro__video {
  position: relative;
  padding-top: 56%; }
  .summer-school-intro__video iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }

.summer-school-intro__address {
  color: #f1c400; }

.summer-school-intro h3 {
  font-size: 23px; }

.summer-school-intro .b-button {
  margin: 5px 0; }

.summer-school-hero {
  clear: both;
  position: relative;
  border-bottom: 10px solid #F1C400; }
  .summer-school-hero__image {
    position: relative; }
    .summer-school-hero__image::before {
      display: block;
      content: "";
      width: 100%;
      padding-top: 21.77083%; }
    .summer-school-hero__image > .content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }
  .summer-school-hero__content {
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: stretch; }
    .summer-school-hero__content .container {
      position: relative;
      display: flex;
      align-items: stretch; }
    .summer-school-hero__content__inner {
      background: #F1C400;
      color: white;
      text-align: center;
      width: 280px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 15px; }
      @media (max-width: 991px) {
        .summer-school-hero__content__inner {
          width: 100%; } }
    .summer-school-hero__content h1 {
      font-size: 35px;
      color: white;
      margin-bottom: 35px; }

.text-and-image.float-text-right .text-and-image__left-col {
  float: right; }

.text-and-image img {
  width: 100%; }

@media (min-width: 992px) {
  .text-and-image.layout-1-3 .text-and-image__left-col {
    width: 33.3%; } }

@media (min-width: 992px) {
  .text-and-image.layout-1-3 .text-and-image__right-col {
    width: 66.6%; } }

.text-two-columns__heading {
  text-align: center;
  margin-bottom: 40px; }

@media (max-width: 767px) {
  .text-two-columns__item {
    margin-bottom: 30px; } }

.text-two-columns__item:nth-of-type(odd) {
  clear: both; }

.text-two-columns__item .b-button {
  margin-top: 15px; }

.text-two-columns__item__inner {
  width: 100%; }

.text-two-columns.column-bg-grey .text-two-columns__item__inner {
  background: #ddd;
  padding: 20px; }

.text-3-columns__heading {
  text-align: center;
  margin-bottom: 40px; }

.text-3-columns__intro {
  margin-bottom: 40px;
  max-width: 40em;
  margin-left: auto;
  margin-right: auto; }

.text-3-columns-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center; }

.text-3-columns__item {
  display: flex;
  align-items: stretch; }
  @media (max-width: 767px) {
    .text-3-columns__item {
      margin-bottom: 40px; } }
  .text-3-columns__item__inner {
    width: 100%; }
  .text-3-columns__item .ratio-image {
    position: relative;
    margin: 2rem auto; }
    .text-3-columns__item .ratio-image::before {
      display: block;
      content: "";
      width: 100%;
      padding-top: 100%; }
    .text-3-columns__item .ratio-image > .content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0; }

.text-3-columns.column-bg-grey .text-3-columns__item__inner {
  background: #ddd;
  padding: 20px; }

.wysiwyg__heading {
  margin-bottom: 40px; }

.wysiwyg__text + .button-wrap {
  margin-top: 40px; }

.wysiwyg--button-center .button-wrap {
  display: flex;
  justify-content: center; }
