.course-details {

  clear:both;

}
#coursetabs {
  border-top:10px solid #F1C400;
  border-bottom:10px solid #F1C400;
  background:#F8F8F8;
  .row {
    margin:0;
  }
}
.course-details__title, .r-tabs-anchor {
  clear:both;
  border-color: #e3e3e3;
  background-color: #ebebeb;
  color: #666;
  display:block!important;
  list-style-type:none;
  text-transform: uppercase;
  text-decoration:none;


  &:before {
    display:none;
  }
  a {
    text-decoration: none;
    color:#666;
  }
  h3 {
    font-size:18px;
    font-family: "Nunito", sans-serif;
    color:#666;
  }
  i {
    width:30px;
    display:inline-block;
  }
  &.r-tabs-state-active {
    background:#F8F8F8;
  }
}
.r-tabs-anchor {
  padding:14px 20px;
}

.course-details__content {
  padding:14px 20px;
  p {
    font-size:18px;
  }
}

.course-details-timetable {

  .course-details-timetable-wrap {
      min-width:700px;
      overflow-x:scroll;
  }

  &__item {
    padding-left:8px;
    padding-right:8px;
    width:14%;
    font-size:14px;
    border-top:1px solid grey;
    float:left;
    //border-right:1px solid grey;

    &:first-of-type {
      border-left:1px solid grey;
    }
    &:last-of-type {
      border-right:1px solid grey;
    }
    p {
      font-size:14px;
      line-height:1.2em;
    }
  }
  &__title {
    background:white;
    width:Auto;
    margin:0 -9px 0 -8px;
    padding:8px;
    color:black;

    p {
      font-weight:bold;
    }
  }
  &__item:last-of-type {
    .course-details-timetable__title {
      margin-right:-8px;
    }
  }
  &__activity {
    padding:8px 0;
    height:7em;
    position:relative;


    &:After {
      content:'';
      border-bottom:1px solid grey;
      display:block;
      position:Absolute;
      bottom:0;
      left:-8px;
      right:-8px;
    }
  }
  .course-details__item__morning {

    &:before {
      content:'';
      border-bottom:1px solid grey;
      display:block;
      position:Absolute;
      top:0;
      left:-8px;
      right:-8px;
    }
  }
}

.course-details-facilities {
  h3 {
    font-size:23px;
  }
}

.course-details-gallery {
  li {
    list-style-type: none;
    padding:0;
    width:19%;
    margin:0 1px 1px 0;
    a {
      width:100%;
    }
    img {
      width:100%;
    }
    &:before {
      display:none;
    }
  }
}