@mixin transition-main {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

@mixin clearfix {
  &:before,
  &:after {content: " ";display: table}
  &:after {clear: both}
}

@mixin centerer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin centerer-vertical {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

@mixin centerer-horizontal {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}

//https://gist.github.com/nathobson/57f2c1a49d8ae2aa1037316268d55a9d
/**************************************************************
-> Usage: @include aspect-ratio(16, 9);
**************************************************************/
@mixin aspect-ratio($width, $height) {
  position: relative;

  &::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }

  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

//https://gist.github.com/nathobson/d9e5e73a9587e784faabe24ee11a16a1

/**************************************************************
-> Usage: @include fluid-type(650px, 1600px, 16px, 32px);
**************************************************************/
@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;

      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }

      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

// Responsive scale mixin *******************************
/**************************************************************
-> Usage: width:get-vw(100px);
**************************************************************/
$vw-viewport: 1900;
@function get-vw($size){
  $vw-context: $vw-viewport * 0.01 * 1px;
  @return $size / $vw-context * 1vw;
  @return $size;
}

