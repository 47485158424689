@import "vendor/responsive-tabs";

/* ==========================================================================
   4. Mixins
   ========================================================================== */

@import "base/mixins";
@import "base/variables";

/* ==========================================================================
   6. Custom Styles
   ========================================================================== */

//Global
@import "global/general-styles";
@import "global/masthead";

//Blocks
@import "blocks/course-details";
@import "blocks/hero";
@import "blocks/price-section";
@import "blocks/promo-boxes";
@import "blocks/promo-circles";
@import "blocks/summer-school-intro";
@import "blocks/summer-school-hero";
@import "blocks/text-and-image";
@import "blocks/text-two-columns";
@import "blocks/text-3-col";
@import "blocks/wysiwyg";