.promo-boxes {
  clear:both;
  text-align:center;
  .row {
    @include clearfix;
    margin:0;
  }
  &__item {
    text-align:center;
    .b-button {
      margin-top:20px;
    }
  }
  &__image {
    @include aspect-ratio(342, 400);
  }
  &__title {
    text-align:center;
    font-family: "bebas-neue", serif;
    text-align: center;
    color: #7f56c5;
    font-size: 30px;
    margin-bottom:17px;
  }
  &__bottom {
    margin-top:40px;
   @include clearfix;


  }
  &__message {
    p, a {
      font-size: 24px;
      font-weight: bold;
    }
  }
}