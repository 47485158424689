.summer-school-hero {
  clear:both;
  position:relative;
  border-bottom:10px solid $yellow;
  &__image {
    @include aspect-ratio(1920, 418);
  }
  &__content {
    position:absolute;
    width:100%;
    top:0;
    bottom:0;
    display:flex;
    align-items: stretch;
    .container {
      position:relative;
      display:flex;
      align-items: stretch;
    }
    &__inner {
      background:#F1C400;
      color:white;
      text-align:center;
      width:280px;
      display:flex;
      justify-content:center;
      flex-direction: column;
      padding:15px;
      @media (max-width:991px) {
        width:100%;
      }
    }
    h1 {
      font-size:35px;
      color:white;
      margin-bottom:35px;
    }
  }
}