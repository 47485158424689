.wysiwyg {
  &__heading {
    margin-bottom:40px;
  }
}

.wysiwyg__text + .button-wrap {
  margin-top:40px;
}

.wysiwyg--button-center {
  .button-wrap {
    display:flex;
    justify-content: center;
  }
}