.text-3-columns {
  &__heading {
    text-align:center;
    margin-bottom:40px;
  }
  &__intro {
    margin-bottom:40px;
    max-width:40em;
    margin-left:auto;
    margin-right:auto;
  }
  &-wrap {
    display:flex;
    flex-wrap:wrap;
    align-items:stretch;
    justify-content: center;
  }
  &__item {
    display:flex;
    align-items:stretch;
    @media (max-width:767px) {
      margin-bottom:40px;
    }
    &__inner {
      width:100%;
    }
    .ratio-image {
      @include aspect-ratio(1,1);
      margin:2rem auto;
    }
  }

}
.text-3-columns.column-bg-grey {
  .text-3-columns__item__inner {
    background:#ddd;
    padding:20px;
  }
}