@media only screen and (min-width: 992px) {
  .l-masthead .header .menu .subnav-container {
    left:-50%;
  }
}
.l-masthead li {
  &:before {
    display:none;
  }
}
.search-bar label {
  opacity:0;
  height:0;
  width:0;
  overflow:hidden;
}
.mobile-menu li {
  &:before {
    display:none;
  }
}
.b-social-mobile a {
  font-size:0;
  color:transparent;
}
.b-social-mobile span {
  font-size:20px;
  color:#fff;
}