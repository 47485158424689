.summer-school-intro {
  &__video {
    position:relative;
    padding-top:56%;
    iframe {
      position:absolute;
      width:100%;
      height:100%;
      top:0;
      left:0;
    }
  }
  &__address {
    color:#f1c400;
  }
  h3 {
    font-size:23px;
  }
  .b-button {
    margin:5px 0;
  }
}