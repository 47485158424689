.text-two-columns {
  &__heading {
    text-align: center;
    margin-bottom:40px;

  }
  &__item {
    //margin-top:40px;
    @media(max-width:767px) {
      margin-bottom:30px;
    }
    &:nth-of-type(odd) {
        clear:both;
    }
    .b-button {
      margin-top:15px;
    }
  }
  &__item__inner {
    width:100%;
  }
}


.text-two-columns.column-bg-grey {
  .text-two-columns__item__inner {
    background:#ddd;
    padding:20px;
  }
}