.text-and-image {
  &.float-text-right {
    .text-and-image__left-col {
      float:right;
    }
  }
  img {
    width:100%;
  }
}

.text-and-image.layout-1-3 {
  .text-and-image__left-col {
    @media (min-width:992px) {
      width:33.3%;
    }
  }
  .text-and-image__right-col {
    @media (min-width:992px) {
      width: 66.6%;
    }
  }
}