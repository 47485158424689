@media (min-width:992px) {
  .l-masthead .header .menu .subnav-container ul.subnav .secondary-subnav ul {
    width:55%;
  }
}
.cen {
  text-align:center;
}
.centre-all {
  text-align:center;
}
ul li {
  margin-left:25px;
  &:before {
    left:-25px;
  }
}

.ratio-image {
  overflow:hidden;
  display:block;
  margin:0;
  @include aspect-ratio(8, 4); //default

  img {
    @include centerer;
    display:block;
    width:100%;

    &.height-100 {
      height:100%;
      width:auto;
      max-width:1000%;
    }
  }
}

.single-course > .row,
.single-course > .col-md-12 {
  max-width:1146px;
  margin-left:auto;
  margin-right:auto;
  padding:0 15px;
  float:none;
}

.page-template-template-pagebuilder .no-content {
  display:none;
}

.pagebuilder section {
  margin:40px 0;
  clear:both;
}

.pagebuilder .no-top {
  margin-top:0!important;
}
.pagebuilder .no-bottom {
  margin-bottom:0!important;
}
.pagebuilder .no-padding {
  margin:0!important;
}

#pagebuilder-top + section {
  margin-top:0;
}

.pagebuilder .contact-module {
  .spacer {
    padding:20px;

  }
}

.pagebuilder {
  section.buttons {
    .container {
      display:flex;
      justify-content: center;
      flex-wrap:wrap;
    }
    .b-button {
      margin:10px;

    }
  }
  .b-button {
    @media (max-width:450px) {
      width:auto;
    }
  }
  .b-button--secondary {
    @media (min-width:769px) {
      padding: 15px 30px;
      font-size: 24px;
      
    }
  }
}
//grid
.pagebuilder {
  .col-md-4 {
    @media (max-width:767px) {
     // width:100%;
    }
  }
}

.spacer-line .container {

  padding-top:40px;
  padding-bottom:40px;
  clear:both;
  hr {
    border:none;
    border-top:1px solid #111;
  }
}

.row-of-images-wrap {
  display:flex;
  justify-content: center;
  //flex-wrap:wrap;
  .ratio-image {
    margin:0 5px 10px 5px;
    width:30%;
   // min-width:100px;
    @include aspect-ratio(1,1);
  }
}

.choose-form {
  .gform_wrapper {
    display:block!important;
  }
}

.consultation-form {
  .gform_title {
    margin-bottom:5px;
  }
  label {
    margin-bottom:5px;
  }
}

.gallery-wrap {
  display:flex;
  @media (max-width:540px) {
    flex-wrap:wrap;
  }
}

.gallery__image {
  width:20%;
  @include aspect-ratio(1,1);
  margin: 0 5px 10px 5px;
  @media (max-width:540px) {
    width:46%;
    margin: 0 2% 10px 2%;
  }
}

.block-contact-module {
  clear:both;
  .b-contact-module__apply {
    display:block;
  }
}

.news {
  clear:both;
  .col-sm-3 {
    @media(max-width:767px) {
      margin-bottom:30px!important;
    }
  }
}

body.logged-in {
  .l-masthead {
    top:29px;
  }
}

.mobile-nav .mobile-menu li a {
  height:auto;
}

.page-id-17195 {
  @media (max-width:991px) {
    padding-top:93px;
  }
  @media (max-width:767px) {
    padding-top:125px;
  }
}

.news .news-container .alm-listing li {
  @media (min-width:992px) {
    width:33.33%;
  }
}

.alm-listing .alm-reveal {
  display:flex;
  flex-wrap:wrap;
}